<template>
  <div>
    <div v-if="order.courierDetails">
      {{ $t('order.courier.header') }}

      <a
        v-if="order.courierDetails.username"
        :href="'http://t.me/' + order.courierDetails.username"
      >
        @{{ order.courierDetails.username }}
      </a>

      <span v-else>
        {{ order.courierDetails.name }}
      </span>
    </div>
    <div v-else>
      {{ $t('order.courier.noCourier') }}
    </div>
    <div v-if="couriers?.getAdmins">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <b-button variant="link">
            <b-icon-pencil-square class="d-none d-lg-inline mr-1" />
            {{ $t('order.courier.change') }}
          </b-button>
        </template>

        <b-dropdown-item
          v-for="courier of couriers?.getAdmins"
          :key="courier.id"
          @click="onChangeCourier(courier.id)"
          class="border-bottom"
        >
          <strong>{{ courier.username }}</strong>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { EOrderStatus, IAdmin, RoleEnum } from 'types';
import { useQuery } from '@vue/apollo-composable';

import { getAdmins } from '../graphql';

import { PreparedOrder } from './Orders.vue';

export default {
  data() {
    return {
      RoleEnum,
    };
  },

  props: ['order'],

  setup(props: { order: PreparedOrder }) {
    const {
      result,
      loading,
      error,
      refetch: reloadOrder,
    } = useQuery<{ getAdmins: IAdmin }>(getAdmins, {
      role: RoleEnum[RoleEnum.COURIER],
    });
    return {
      reloadOrder,
      couriers: result,
      loading,
      error,
    };
  },

  methods: {
    async onChangeCourier(courierId: string) {
      this.$emit('courierChanged', {
        orderId: this.order.id,
        courierId,
      });
    },
  },
};
</script>
