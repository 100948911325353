<template>
  <div class="order-page">
    <h1>{{ $t('order.page_title', { threadId: data?.order.threadId }) }}</h1>
    <div v-if="error">Error: {{ error.message }}</div>
    <div v-if="loading">Loading...</div>
    <div v-if="data?.order">
      <b-row>
        <b-col>
          <div class="form-group">
            <label for="name">{{ $t('order.customer.header') }} </label>
            <div class="customer-info"></div>
            <p>
              <strong>{{ $t('order.customer.name') }}:</strong>
              {{ data.order.user.name }}
            </p>
            <p>
              <strong>{{ $t('order.customer.address') }}:</strong>
              {{ data.order.user.address }}
            </p>
            <p>
              <strong>{{ $t('order.customer.addressAdditions') }}:</strong>
              {{ data.order.user.additionalAddress }}
            </p>
            <p>
              <strong>{{ $t('order.customer.contact') }}:</strong>
              {{ data.order.user.contactType }}: {{ data.order.user.phone }}
            </p>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="restaurant">{{ $t('order.restaurant.header') }} </label>
            <div class="restaurant-info"></div>
            <p>
              <strong>{{ $t('order.restaurant.name') }}:</strong>
              {{ data.order.restaurant.name }}
            </p>
            <p>
              <strong>{{ $t('order.restaurant.address') }}: </strong>
              <a :href="data.order.restaurant.address">{{
                data.order.restaurant.address
              }}</a>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="form-group">
            <strong>{{ $t('order.items.header') }}:</strong>
            <ol>
              <li v-for="item in data.order.items" :key="item.id">
                {{ item.dish.name }} - {{ item.dish.price }}
                {{ data.order.city.currency }} x {{ item.count }} =
                {{ item.sum }} {{ data.order.city.currency }}
              </li>
            </ol>
            <p>
              <strong>{{ $t('order.items.sum') }}:</strong>
              {{ data.order.sum }} {{ data.order.city.currency }}
            </p>
            <p>
              <strong>{{ $t('order.items.delivery') }}:</strong>
              {{ data.order.deliveryCost }} {{ data.order.city.currency }}
            </p>
            <p>
              <strong>{{ $t('order.items.discount') }}:</strong>
              {{ data.order.discount }} {{ data.order.city.currency }}
            </p>
            <p>
              <strong>{{ $t('order.items.totalWithDelivery') }}:</strong>
              {{ data.order.totalSum }}
              {{ data.order.city.currency }}
            </p>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <strong>{{ $t('order.status') }}: </strong>
            {{ $t(`orders.statuses.${EOrderStatus[data.order.status]}`) }}
            <OrderStatusEditor
              :order="data.order"
              @statusChanged="onStatusChanged"
            />
          </div>
          <div class="form-group">
            <strong>{{ $t('order.paymentMethod') }}: </strong>
            {{ EPaymentMethod[data.order.paymentMethod] }}
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script lang="ts">
import { EOrderStatus, EPaymentMethod, IOrder } from 'types';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router/composables';

import { getOrder, updateOrder } from '../graphql';

import OrderStatusEditor from './OrderStatusEditor.vue';

export default {
  components: {
    OrderStatusEditor,
  },
  data() {
    return {
      EOrderStatus,
      EPaymentMethod,
    };
  },
  methods: {
    onStatusChanged(data: { status: EOrderStatus }) {
      this.updateStatus({
        id: this.data.order.id,
        status: data.status,
      });
    },
  },
  setup() {
    const route = useRoute();
    const {
      result,
      loading,
      error,
      refetch: reloadOrder,
    } = useQuery<{ order: IOrder }>(getOrder, {
      threadId: route.params.id,
    });
    const { mutate: updateStatus } = useMutation<{ updateOrder: IOrder }>(
      updateOrder,
      () => ({
        update: (cache, { data }) => {
          reloadOrder();
        },
      }),
    );
    return {
      reloadOrder,
      updateStatus,
      data: result,
      loading,
      error,
    };
  },
};
</script>

<style scoped>
.order-page {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
