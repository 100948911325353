<template>
  <div v-if="restaurants">
    <b-row class="mb-3">
      <b-col class="mb-2" align-self="center">
        <h1 v-if="id" class="m-0">{{ $t('ad.edit') }}</h1>
        <h1 v-else class="m-0">{{ $t('ad.new') }}</h1>
      </b-col>

      <b-col sm="auto" cols="12" align-self="center">
        <b-button
          squared
          type="submit"
          variant="primary"
          @click="onSubmit"
          :disabled="lengthValidation === false || uploading"
        >
          <b-spinner v-if="sending" small />
          {{ $t('ad.save') }}
        </b-button>
      </b-col>

      <b-col cols="12" md="auto" align-self="center">
        <b-button
          squared
          v-on:click.prevent="onDelete"
          variant="outline-danger"
          v-if="id"
        >
          {{ $t('ad.delete') }}
        </b-button>
      </b-col>
    </b-row>

    <b-form @submit.prevent="onSubmit">
      <b-row v-if="data.publishDate">
        <b-col>
          <span>{{
            $t('ad.publishDate', { publishDate: data.publishDate })
          }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <label>{{ $t('ad.text', { text: '' }) }}</label>
            <b-form-textarea
              v-bind:placeholder="$t('ad.text_placeholder')"
              class="input-area"
              v-model="data.text"
              rows="8"
              :state="lengthValidation"
              required
            />

            <b-form-invalid-feedback>
              {{ $t('ad.lengthValidation') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group v-if="isAdmin || isCityAdmin">
        <b-row>
          <b-col>
            <label for="publishDate" class="text">
              {{
                $t('ad.publishDate', {
                  publishDate: id ? data.publishDate : '',
                })
              }}
            </label>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col md="6">
            <b-form-datepicker
              v-model="data.publishDate"
              id="publishDate"
              type="time"
              today-button
              required
            />
          </b-col>

          <b-col md="6">
            <b-form-timepicker
              @context="onPublishContext"
              :value="publishTime"
              id="publishTime"
              type="time"
              now-button
              required
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <label for="unpublishDate" class="text">
              {{
                $t('ad.unpublishDate', {
                  unpublishDate: id ? data.unpublishDate : '',
                })
              }}
            </label>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-datepicker
              v-model="data.unpublishDate"
              id="unpublishDate"
              type="time"
              today-button
              required
            />
          </b-col>

          <b-col md="6">
            <b-form-timepicker
              @context="onUnpublishContext"
              :value="unpublishTime"
              id="unpublishTime"
              type="time"
              now-button
              required
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-row class="mb-3" v-if="(isAdmin || isCityAdmin) && !id">
        <b-col>
          <label for="input-8">
            {{ $t('ad.restaurant', { restaurant: '' }) }}
          </label>
          <b-form-select
            v-model="data.restaurantId"
            :options="restaurants"
            required
          />
        </b-col>
      </b-row>

      <photo-uploader
        v-if="!this.videoFileId"
        :disabled="uploading"
        :title="$t('ad.image')"
        :cityId="cityId"
        :telegramFileId="telegramFileIds"
        :multiple="true"
        :videoAllowed="false"
        @uploaded="onUploadedImages"
        @uploading="onUploading"
      />

      <photo-uploader
        v-if="this.telegramFileIds.length === 0"
        :disabled="uploading"
        :title="$t('ad.video')"
        :cityId="cityId"
        :telegramFileId="videoFileId"
        :multiple="false"
        @uploaded="onUploadedVideo"
        @uploading="onUploading"
      />

      <b-row align-v="center" class="mt-3">
        <b-col md="auto">
          <b-button
            class="pr-3 pl-3"
            squared
            type="submit"
            variant="primary"
            :disabled="lengthValidation === false || uploading"
          >
            <b-spinner v-if="sending" small />
            {{ $t('ad.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAd, IRestaurant, RoleEnum } from 'types';

import PhotoUploader from './PhotoUploader.vue';

type AdOptions = {
  text: string;
  telegramChatId: number;
  restaurantId: string;
  telegramFileIds: string[];
  videoFileId?: string;
  publishTimestamp?: number;
  unpublishTimestamp?: number;
};

@Component({
  name: 'Ad',
  components: { 'photo-uploader': PhotoUploader },
})
export default class Ad extends Vue {
  @Prop() id: string;
  data: Partial<IAd> = {};
  telegramFileIds: string[] = [];
  videoFileId?: string;
  restaurants = [];
  restaurantsList = [];
  publishTime = '';
  unpublishTime = '';
  publishContext = null;
  unpublishContext = null;
  chatId: number;
  sending = false;
  uploading = false;

  async mounted() {
    // this.id will be empty if an admin would like to create new ad
    // in other cases, there will be some value.

    if (this.id) {
      // Gets details of an specified ad

      const response = await this.axios.get<IAd>('/advertisements/' + this.id);

      this.data = {
        ...response.data,
        publishDate: new Date(response.data.publishDate),
        unpublishDate: new Date(response.data.unpublishDate),
      };

      this.videoFileId = response.data.videoFileId;

      // Formats dates and times into something that's human-readable

      this.publishTime =
        this.data.publishDate.getHours() +
        ':' +
        this.data.publishDate.getMinutes() +
        ':00';

      this.unpublishTime =
        this.data.unpublishDate.getHours() +
        ':' +
        this.data.unpublishDate.getMinutes() +
        ':00';
    }

    /**
     * Only admins can specify restaurant to advertise.
     *
     * Restaurant owners can use only their own restaurants.
     */

    if (this.isAdmin) {
      const response = await this.axios.get<IRestaurant[]>('/restaurants', {
        params: { enabled: true },
      });

      this.updateRestaurantsLists(response.data);
    }

    if (this.isCityAdmin) {
      const cityId = this.$store.getters.cityId as string;

      const response = await this.axios.get<IRestaurant[]>(
        `/restaurants/city/${cityId}`,
        { params: { enabled: true } },
      );

      this.updateRestaurantsLists(response.data);
    }

    // Gets chat where the advertisement must be published

    this.axios.get<number>('/advertisements/chat').then(({ data }) => {
      this.chatId = data;
    });
  }

  updateRestaurantsLists(restaurants: IRestaurant[] = []) {
    this.restaurantsList = restaurants;
    this.restaurants = restaurants.map((item) => ({
      value: item.id,
      text: item.name,
    }));
  }

  async onDelete() {
    await this.axios.delete(`/advertisements/${this.id}`);
    this.$router.push({ path: '/ads' });
  }

  onUploadedImages(telegramFileIds?: string[]) {
    this.uploading = false;

    if (!telegramFileIds) {
      this.$bvToast.toast('Error while uploading image', {
        title: 'Error',
        variant: 'danger',
      });
      return;
    }

    this.telegramFileIds = [];
    this.telegramFileIds.push(...telegramFileIds);
  }

  onUploadedVideo(telegramFileId?: string) {
    this.uploading = false;

    if (!telegramFileId) {
      this.$bvToast.toast('Error while uploading video', {
        title: 'Error',
        variant: 'danger',
      });
      return;
    }

    this.videoFileId = telegramFileId;
  }

  onUploading() {
    this.uploading = true;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get roleId() {
    return this.$store.getters.roleId;
  }

  get cityId() {
    switch (this.roleId) {
      case RoleEnum.ADMIN:
        if (this.id) {
          const cityId = this?.data?.restaurant.id;

          return cityId;
        } else {
          const currentRestaurant = this.restaurantsList.find(
            ({ id }) => id === this.data.restaurantId,
          );

          return currentRestaurant?.cityId;
        }
      case RoleEnum.CITY_ADMIN:
        return this.$store.getters.cityId;
      case RoleEnum.RESTAURANT_OWNER:
        return this.$store.state.restaurant?.cityId;
      default:
        return null;
    }
  }

  get lengthValidation() {
    return this.data.text?.length >= 1024 ? false : null;
  }

  onPublishContext(ctx) {
    this.publishContext = ctx;
  }

  onUnpublishContext(ctx) {
    this.unpublishContext = ctx;
  }

  async onSubmit() {
    this.sending = true;

    const {
      text,
      restaurantId,
      publishDate: rawPublishDate,
      unpublishDate: rawUnpublishDate,
    } = this.data;

    // These values may be sent in string form, so we must parse them

    const publishDate =
      rawUnpublishDate && typeof rawPublishDate === 'string'
        ? new Date(Date.parse(rawPublishDate))
        : rawPublishDate;

    const unpublishDate =
      rawUnpublishDate && typeof rawUnpublishDate === 'string'
        ? new Date(Date.parse(rawUnpublishDate))
        : rawUnpublishDate;

    const form: AdOptions = {
      text,
      telegramChatId: this.chatId, // TODO: move it to backend
      restaurantId: this.isRestaurantOwner
        ? this.$store.getters.restId
        : restaurantId,
      telegramFileIds: this.telegramFileIds,
      videoFileId: this.videoFileId,
    };

    /**
     * Restaurant owners can not set time via time-picker, so we must check
     * if this values are not empty.
     */

    if (publishDate && unpublishDate) {
      publishDate.setHours(
        this.publishContext.hours,
        this.publishContext.minutes,
      );

      unpublishDate.setHours(
        this.unpublishContext.hours,
        this.unpublishContext.minutes,
      );

      form.publishTimestamp = publishDate.getTime();
      form.unpublishTimestamp = unpublishDate.getTime();
    }

    /**
     * If this.id is not empty, then an admin's editing the ad and we need to just PUT
     * new data inside it.
     *
     * If this value is empty, then we just creating new ad via POST request.
     */

    if (this.id) {
      await this.axios.put<IAd>(`/advertisements/${this.id}`, form);
      this.sending = false;
      this.$router.push('/ads');
    } else {
      try {
        const result = await this.axios.post<IAd>('/advertisements', form);
        if (result instanceof Error) {
          const extraMessage: string =
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (result as any)?.response?.data?.message || result.message;
          // eslint-disable-next-line no-console
          this.$bvModal.msgBoxOk(
            `${this.$t(
              'validation.cantPublishAds',
            ).toString()}: ${extraMessage}`,
            {
              title: 'error',
              variant: 'warning',
            },
          );
          this.sending = false;
        } else {
          this.$router.push('/ads');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }
}
</script>
