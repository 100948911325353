import { markRaw } from 'vue';
import { gql } from '@apollo/client/core';

export const getOrder = markRaw(gql`
  query Query($threadId: String!) {
    order(threadId: $threadId) {
      id
      cityId
      city {
        id
        name
        name_ru
        currency
      }
      userId
      user {
        id
        name
        additionalAddress
        address
        contactType
        phone
      }
      restaurantId
      restaurant {
        id
        cityId
        name
        name_ru
        description
        description_ru
        address
      }
      sum
      courierTelegramId
      items {
        id
        dish {
          id
          name
          name_ru
          description
          description_ru
          price
        }
        count
        sum
      }
      threadId
      deliveryCost
      discount
      totalSum
      status
      paymentMethod
    }
  }
`);

export const updateOrder = markRaw(gql`
  mutation Mutation($id: String!, $status: Int, $courierId: String) {
    updateOrder(id: $id, order: { status: $status, courierId: $courierId }) {
      id
      status
    }
  }
`);
