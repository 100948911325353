<template>
  <b-container fluid>
    <h1>Статистика</h1>

    <hr />

    <b-row>
      <b-col v-if="this.isAdmin">
        <b-form-group label="Выберите город:">
          <b-form-select
            id="citypicker"
            v-model="city"
            :options="cities"
            required
            @input="() => updateStats()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('stats.startDate')">
          <b-form-datepicker
            id="datepicker1"
            v-model="dateFrom"
            @input="updateStats"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group :label="$t('stats.endDate')">
          <b-form-datepicker
            id="datepicker2"
            v-model="dateTo"
            @input="updateStats"
            class="mb-2"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <b-overlay :show="!city && this.isAdmin" variant="dark">
      <b-row>
        <b-col sm="12" xl="6">
          <h5>График заказов:</h5>

          <apexchart
            v-if="city || isCityAdmin"
            ref="ordersChart"
            type="line"
            width="500"
            :options="chartOptions"
            :series="series"
          />
        </b-col>

        <b-col sm="12" xl="6">
          <h5>Пользователи</h5>

          <div>
            Новых пользователей:
            <b-badge>{{ newUsers }}</b-badge>
          </div>

          <div>
            Количество активных пользователей:
            <b-badge>{{ activeUsers }}</b-badge>
          </div>

          <div>
            Количество пользователей, заблокировавших бота:
            <b-badge id="tooltip-target-1" variant="danger">
              {{ blockedUsersAmount }}
            </b-badge>

            <b-tooltip target="tooltip-target-1">
              <b-badge variant="danger">Красный цвет</b-badge>
              вокруг значения поля означает, что параметр не зависит от
              выбранной даты
            </b-tooltip>
          </div>

          <div>
            Количество пользователей у которых в данный момент отложены товары в
            корзине:
            <b-badge id="tooltip-target-2" variant="danger">{{
              cartsAmount
            }}</b-badge>

            <b-tooltip target="tooltip-target-2">
              <b-badge variant="danger">Красный цвет</b-badge>
              вокруг значения поля означает, что параметр не зависит от
              выбранной даты
            </b-tooltip>
          </div>

          <h5 class="mt-3">Заказы</h5>

          <div>
            Количество всех заказов:
            <b-badge>{{ orderStatistics.amountOfOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей, <em>сделавших 2 заказа</em>:
            <b-badge>{{ orderStatistics.amountOfTwoOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей, <em>сделавших 3 заказа</em>:
            <b-badge>{{ orderStatistics.amountOfThreeOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей,
            <em>сделавших 4 и более заказа</em>:
            <b-badge>
              {{ orderStatistics.amountOfFourAndMoreOrders }}
            </b-badge>
          </div>
          <h5 class="mt-3">{{ $t('stats.funnel.header') }}</h5>

          <div>
            {{ $t('stats.funnel.medianTimeToFirstOrder') }}:
            <b-badge>{{ orderFunnel.medianTimeToFirstOrder }}</b-badge>
          </div>

          <div>
            {{ $t('stats.funnel.medianTimeToSecondOrder') }}:
            <b-badge>{{ orderFunnel.medianTimeToSecondOrder }}</b-badge>
          </div>

          <div>
            {{ $t('stats.funnel.medianTimeToTensOrder') }}:
            <b-badge>{{ orderFunnel.medianTimeToTenOrder }}</b-badge>
          </div>

          <div>
            {{ $t('stats.funnel.averageTimeToFirstOrder') }}:
            <b-badge>{{ orderFunnel.averageTimeToFirstOrder }}</b-badge>
          </div>

          <div>
            {{ $t('stats.funnel.averageTimeToSecondOrder') }}:
            <b-badge>{{ orderFunnel.averageTimeToSecondOrder }}</b-badge>
          </div>

          <div>
            {{ $t('stats.funnel.averageTimeToTensOrder') }}:
            <b-badge>{{ orderFunnel.averageTimeToTenOrder }}</b-badge>
          </div>
        </b-col>
      </b-row>

      <hr />

      <h5>Другое</h5>

      <div>
        Среднее время ответа бота на запрос пользователя:
        <b-badge>{{ executionTime || '?' }} ms</b-badge>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="lock-fill"
            font-scale="3"
            animation="cylon-vertical"
            variant="primary"
            class="mb-3"
          ></b-icon>

          <p class="text-white">Выберите город для показа статистики</p>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import { ICity, RoleEnum } from 'types';
import { addDays, differenceInDays, startOfDay } from 'date-fns';

type OrderStatistics = {
  amountOfOrders: number;
  amountOfTwoOrders: number;
  amountOfThreeOrders: number;
  amountOfFourAndMoreOrders: number;
};

interface OrderFunnel {
  medianTimeToFirstOrder: string;
  medianTimeToSecondOrder: string;
  medianTimeToTenOrder: string;
  averageTimeToFirstOrder: string;
  averageTimeToSecondOrder: string;
  averageTimeToTenOrder: string;
}

type Queries = {
  dateTo: string;
  dateFrom: string;
  cityId: string;
};

type ChartData = {
  data: number[];
  columns: string[];
};

const defaultFunnel = {
  medianTimeToFirstOrder: '?',
  medianTimeToSecondOrder: '?',
  medianTimeToTenOrder: '?',
  averageTimeToFirstOrder: '?',
  averageTimeToSecondOrder: '?',
  averageTimeToTenOrder: '?',
};

@Component({ name: 'Stats' })
export default class Stats extends Vue {
  newUsers = 0;
  executionTime = 0;
  activeUsers = 0;
  blockedUsersAmount = 0;
  cartsAmount = 0;
  orderStatistics: OrderStatistics = {
    amountOfOrders: 0,
    amountOfTwoOrders: 0,
    amountOfThreeOrders: 0,
    amountOfFourAndMoreOrders: 0,
  };

  orderFunnel: OrderFunnel = defaultFunnel;

  chartOptions: ApexOptions = {
    chart: { id: 'vuechart' },
    dataLabels: { enabled: true },
    colors: ['#F15065', '#545454'],
  };

  series = [];

  dateFrom: string = addDays(new Date(), -7).toISOString();
  dateTo: string = startOfDay(new Date()).toISOString();

  city = null;
  cities = [];

  async mounted() {
    await this.axios.get<ICity[]>('/cities').then(({ data }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });

    await this.updateStats();
  }

  updateStats<T>() {
    const params = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      cityId: this.isCityAdmin ? this.cityId : this.city.id,
    } as unknown as Queries;

    if (!params.dateTo) {
      params.dateTo = new Date().toISOString();
    }

    if (params.dateFrom) {
      const days = differenceInDays(
        new Date(params.dateTo),
        new Date(params.dateFrom),
      );

      this.chartOptions.dataLabels.enabled = days > 7;

      if (days > 365) {
        this.dateTo = addDays(new Date(params.dateFrom), 365).toISOString();
        params.dateTo = this.dateTo;
      }
    }

    // Updates chart
    this.axios.get<ChartData>('/stats/orders', { params }).then(({ data }) => {
      this.series = [{ name: 'orders', data: data.data }];

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data.columns,
        },
      };
    });

    this.orderFunnel = defaultFunnel;

    this.axios.get('/stats/newUsers', { params }).then((response) => {
      this.newUsers = response.data;
    });

    this.axios.get('/stats/executionTime', { params }).then((response) => {
      this.executionTime = response.data;
    });

    this.axios.get('/stats/activeUsers', { params }).then((response) => {
      this.activeUsers = response.data;
    });

    this.axios.get('/stats/ordersAmount', { params }).then((response) => {
      this.orderStatistics = response.data;
    });

    this.axios.get('/stats/ordersFunnel', { params }).then((response) => {
      this.orderFunnel = response.data;
    });

    this.axios.get('/stats/blockedUsersAmount', { params }).then((response) => {
      this.blockedUsersAmount = response.data;
    });

    this.axios.get('/stats/cartsAmount', { params }).then((response) => {
      this.cartsAmount = response.data;
    });
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }
}
</script>

<style scoped></style>
