import { gql } from '@apollo/client/core';
import { markRaw } from 'vue';

export const getAdmins = markRaw(gql`
  query Query($role: RoleEnum!) {
    getAdmins(role: $role) {
      id
      username
      roleId
    }
  }
`);
