<template>
  <div>
    <b-row class="mb-3" align-h="between">
      <b-col>
        <h1 class="m-0 mb-2">{{ $t('orders.title') }}</h1>
      </b-col>

      <b-col align-self="center" class="d-flex justify-content-end">
        <b-button variant="outline-danger" @click="exportExcel">
          <b-spinner small v-if="exporting"></b-spinner>
          {{ $t('orders.export') }}
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="3">
          <label for="from-date">
            {{ $t('orders.startingDate') }}
          </label>
          <b-form-datepicker
            v-model="filter.from"
            @input="onSubmit"
            id="from-date"
            type="date"
            class="mb-2"
          />
        </b-col>

        <b-col md="3">
          <label for="to-date">
            {{ $t('orders.endingDate') }}
          </label>
          <b-form-datepicker
            v-model="filter.to"
            @input="onSubmit"
            id="to-date"
            type="date"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>

        <b-col v-if="isAdmin" md="3">
          <label for="select-city">{{ $t('orders.city') }}</label>

          <b-form-select
            v-model="selectedCity"
            :options="cities"
            :value="selectedCity"
            @input="onSubmit"
            class="mb-2"
          />
        </b-col>

        <b-col :md="isAdmin ? 3 : 6" v-if="!isRestaurantOwner">
          <label>
            {{ $t('orders.restaurants') }}
          </label>

          <multiselect
            @input="onSubmit"
            v-model="filter.restaurants"
            :multiple="true"
            :searchable="true"
            :close-on-select="false"
            :placeholder="$t('orders.restaurantDisclaimer')"
            label="name"
            track-by="id"
            :options="getRestaurants()"
          >
          </multiselect>
        </b-col>

        <b-col md="12" class="mt-3">
          <b-form-checkbox
            v-model="showNumbers"
            v-if="!isRestaurantOwner"
            inline
          >
            {{ $t('orders.showNumbers') }}
          </b-form-checkbox>

          <b-form-checkbox v-model="shortList" inline>
            {{ $t('orders.enableBriefSummary') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-3">
      <b-col md="12" class="mb-3">
        <h5 class="text-monospace">
          {{ $t('orders.totalSum') }}
          <span v-if="isAdmin">{{ sums }}</span>
          <span v-else>{{ sum }}</span>
        </h5>
        <h5 class="text-monospace">
          {{ $t('orders.totalSumWithoutDelivery') }}
          <span v-if="isAdmin">{{ sumsWithoutDelivery }}</span>
          <span v-else>{{ sum_without_delivery }}</span>
        </h5>
        <h5 class="text-monospace">
          {{ $t('orders.ordersAmount') }}
          {{ count }}
        </h5>
        <h5 class="text-monospace">
          {{ $t('orders.averageCheque') }}
          {{ avgCheque }}
        </h5>
      </b-col>

      <hr />

      <div class="col-md-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </template>

          <b-row class="mt-3" v-if="!shortList">
            <b-col
              v-for="order in getOrders()"
              :key="order.id"
              xl="12"
              lg="12"
              md="6"
              sm="12"
              class="mb-3"
            >
              <b-card
                body-class="pt-lg-2 pt-auto"
                header-class="bg-white p-0 m-0"
                :style="order.colors.expandedColorStyle"
              >
                <!-- Header of card -->

                <template #header>
                  <b-row
                    align-h="start"
                    class="text-center align-items-center m-0 pt-2 pb-2 pt-lg-0 pb-lg-0"
                    align-v="end"
                  >
                    <!-- :style="order.headerStyle" -->
                    <b-col sm="12" lg="auto" class="pr-0">
                      <h4 class="m-0">
                        <b-link :to="'/orders/' + order.threadId">
                          №{{ order.threadId }}
                        </b-link>
                      </h4>
                    </b-col>

                    <b-col sm="12" lg="auto">
                      <span class="text-secondary">
                        {{ order.restaurant.name }}
                      </span>
                    </b-col>

                    <b-col
                      class="d-none d-lg-flex align-items-center justify-content-end"
                    >
                      <order-status
                        v-if="!isRestaurantOwner"
                        :order="order"
                        @statusChanged="onOrderChanged"
                      />

                      <b-link
                        variant="link"
                        @click="order.chatShown = !order.chatShown"
                      >
                        <b-icon-chat-fill
                          class="mr-1"
                          v-if="order.messagesAmount !== '0'"
                        />
                        <b-icon-chat class="mr-1" v-else />
                        {{ $t('orders.openChat') }}
                      </b-link>
                    </b-col>
                  </b-row>
                </template>

                <!-- Order status -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.status') }}
                  <b-badge :style="order.colors.statusBadgeColor">
                    {{ $t(`orders.statuses.${EOrderStatus[order.status]}`) }}
                  </b-badge>
                </div>

                <!-- Full order sum -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.sum') }}
                  <code class="text-dark">
                    {{ order.sum + order.deliveryCost }}
                    {{ ' ' + order.currency }}
                  </code>
                </div>

                <!-- Sum without delivery -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.sumWithoutDelivery') }}
                  <code class="text-dark">
                    {{ order.sum }}
                    {{ ' ' + order.currency }}
                  </code>
                </div>

                <!-- Order date -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center text-nowrap"
                >
                  {{ $t('orders.orderedAt') }}
                  <em>
                    {{ new Date(order.createdAt) | date('d LLL, H:mm:ss') }}
                  </em>
                </div>

                <!-- Customer name -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.customerName') }}
                  <em>
                    {{ order.user.name }}
                    <b-link
                      v-if="isCityAdmin || isAdmin || isOperator"
                      variant="link"
                      @click="cleanAddress(order.userId)"
                      title="Очистить адрес доставки"
                      >x</b-link
                    >
                  </em>
                </div>

                <!-- Contact type -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.contactType') }}
                  <em>
                    {{ order.user.contactType }}
                  </em>
                </div>

                <!-- Phone number of the customer -->

                <div
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.phone') }}
                  <div v-if="!showNumbers || isRestaurantOwner">**********</div>
                  <code class="text-dark" v-else>
                    {{ order.user.phone }}
                  </code>
                </div>

                <!-- Courier name -->

                <div
                  v-if="!isRestaurantOwner"
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  <order-courier
                    :order="order"
                    @courierChanged="onOrderChanged"
                  />
                </div>

                <!-- Invoice -->

                <div
                  v-if="(isCityAdmin || isAdmin) && order.invoiceId"
                  class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                >
                  {{ $t('orders.invoiceId') }}

                  <code>
                    {{ order.invoiceId }}
                  </code>
                </div>

                <div v-if="showPaymentData(order)">
                  <hr />

                  <!-- Provider (Stripe) charge id, visible for admins only -->

                  <div
                    class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                  >
                    {{ $t('orders.paymentReceipt') }}

                    <code class="text-dark">
                      {{ order.paymentReceipt?.providerPaymentChargeId }}
                    </code>
                  </div>

                  <!-- Service fee, visible for admins only-->

                  <div
                    class="mt-3 mt-lg-0 d-flex justify-content-between align-items-center"
                  >
                    {{ $t('orders.serviceFee') }}

                    <code class="text-dark">
                      {{ Number(order.serviceFee).toFixed(2) }}
                      {{ order.currency }}
                    </code>
                  </div>
                </div>

                <hr class="d-lg-none" />

                <!-- Footer -->

                <div
                  class="text-center d-lg-none d-flex align-items-center justify-content-between pr-3"
                >
                  <order-status class="p-0" :order="order" />

                  <div>
                    <b-link
                      variant="link"
                      @click="order.chatShown = !order.chatShown"
                    >
                      {{ $t('orders.openChat') }}
                    </b-link>
                  </div>
                </div>
              </b-card>

              <chat
                :threadId="order.threadId"
                :customerName="order.user.name"
                :shown="order.chatShown"
              />
            </b-col>
          </b-row>

          <b-list-group v-else>
            <b-list-group-item
              v-for="order in getOrders()"
              :key="order.id"
              class="p-2 pr-3 mb-1"
            >
              <div class="d-flex justify-content-start">
                <div class="border-right mr-2 pr-1 h-100 align-self-center">
                  <b-button
                    variant="link"
                    @click="order.chatShown = !order.chatShown"
                  >
                    <b-icon-chat-fill v-if="order.messagesAmount !== '0'" />
                    <b-icon-chat v-else />
                  </b-button>
                </div>

                <div class="d-flex flex-column">
                  <div>
                    <strong>№{{ order.threadId }}.</strong>
                    <span class="text-secondary ml-1">
                      {{ order.restaurant.name }}
                    </span>
                  </div>

                  <div>
                    <b-badge :style="order.colors.statusBadgeColor">
                      {{ order.status }}
                    </b-badge>
                    {{ $t('orders.paid') }}
                    {{ order.sum }}
                    {{ order.currency }}
                    ({{ order.sum + order.deliveryCost }} {{ order.currency }})
                  </div>
                </div>

                <div class="ml-auto text-right">
                  <div>
                    {{ new Date(order.createdAt) | date('d/M/yyyy') }}
                  </div>
                  <div>
                    {{ new Date(order.createdAt) | date('HH:mm') }}
                    <b-icon-alarm />
                  </div>
                </div>
              </div>

              <chat
                class="border-top mt-2"
                :threadId="order.threadId"
                :customerName="order.user.name"
                :shown="order.chatShown"
              />
            </b-list-group-item>
          </b-list-group>
        </b-skeleton-wrapper>
      </div>
    </b-row>
  </div>
</template>

<script lang="ts">
import { format } from 'date-fns';
import * as _ from 'lodash';
import VueMultiselect from 'vue-multiselect';
import { Component, Vue } from 'vue-property-decorator';
import {
  ICity,
  IOrder,
  IRestaurant,
  RoleEnum,
  EOrderStatus,
  ORDER_STATUSES_COLORS,
} from 'types';

import ChatModal from './Chat.vue';
import OrderStatusEditor from './OrderStatusEditor.vue';
import OrderCourier from './OrderCourierEditor.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

export interface PreparedOrder extends IOrder {
  currency: string;
  chatShown: boolean;
  colors: {
    expandedColorStyle: string;
    collapsedColorStyle: string;
    statusBadgeColor: string;
    // headerStyle: string
  };
}

@Component({
  name: 'Order',
  components: {
    multiselect: VueMultiselect,
    chat: ChatModal,
    'order-status': OrderStatusEditor,
    'order-courier': OrderCourier,
  },
})
export default class Order extends Vue {
  EOrderStatus = EOrderStatus;
  // Fields for setting loaders

  minimalStatus = EOrderStatus.CONFIRMED;
  loading = false;
  exporting = false;

  // For filtering of orders

  orders: PreparedOrder[] = [];
  showNumbers = false;
  restaurants: IRestaurant[] = [];
  cities: { value: ICity; text: string }[] = [];
  selectedCity: ICity = null;
  filter: {
    from: Date;
    to: Date;
    restaurants: IRestaurant[];
  } = {
    from: new Date(),
    to: new Date(),
    restaurants: [],
  };

  // Needed for showing the chat

  customerName = '';

  // Needed for exporting to Excel

  locale = localStorage.getItem(`${storagePrefix}-locale`);

  // Needed for changing the appearance of the order list

  shortList = false;

  async onOrderChanged({
    orderId,
    status,
    courierId,
  }: {
    orderId: IOrder['id'];
    status?: EOrderStatus;
    courierId?: string;
  }) {
    await this.axios.put('/orders/' + orderId, { status, courierId });

    await this.onSubmit();
  }

  getOrders(): PreparedOrder[] {
    return this.orders.slice().sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }

      if (a.createdAt > b.createdAt) {
        return -1;
      }

      return 0;
    });
  }

  getRestaurants(): IRestaurant[] {
    return this.restaurants.slice();
  }

  showPaymentData(order: IOrder) {
    const adminHasRights = Boolean(this.isAdmin || this.isCityAdmin),
      hasReceipt = Boolean(order.paymentReceipt);

    return adminHasRights && hasReceipt;
  }

  get sum(): number {
    return this.orders.reduce((acc, order) => {
      if (order.status < EOrderStatus.CONFIRMED) {
        return acc;
      }
      return acc + order.sum + order.deliveryCost;
    }, 0);
  }

  get sum_without_delivery(): number {
    return this.orders.reduce((acc, order) => {
      if (order.status < EOrderStatus.CONFIRMED) {
        return acc;
      }
      return acc + order.sum;
    }, 0);
  }

  get count(): number {
    return (
      this.orders.filter((order) => order.status >= EOrderStatus.CONFIRMED)
        .length || 0
    );
  }

  get avgCheque(): string {
    if (this.count < 1) return '0';

    return (this.sum / this.count).toFixed(2);
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get restaurantId() {
    return this.$store.getters.restId;
  }

  get sums(): string {
    if (this.orders.length < 1) return '';

    const ordersByCity = _.groupBy(
      this.orders,
      (order) => order.restaurant.cityId,
    );

    const sums: { [key: ICity['currency']]: number } = _.reduce(
      ordersByCity,
      (result: { [key: ICity['currency']]: number }, orders, cityId) => {
        const city: ICity = this.cities.find(
          (city) => city.value.id === cityId,
        )?.value;

        result[city.currency] = orders.reduce((acc, order) => {
          if (order.status < EOrderStatus.CONFIRMED) {
            return acc;
          }
          return acc + order.sum + order.deliveryCost;
        }, 0);

        return result;
      },
      {},
    );

    const sumsStringified = _.map(
      sums,
      (value: number, key: string) => value + ' ' + key,
    ) as string[];

    return _.join(sumsStringified, ', ') as string;
  }

  get sumsWithoutDelivery(): string {
    if (this.orders.length < 1) return '';

    const ordersByCity = _.groupBy(
      this.orders,
      (order) => order.restaurant.cityId,
    );

    const sums: { [key: ICity['currency']]: number } = _.reduce(
      ordersByCity,
      (
        result: { [key: ICity['currency']]: number },
        orders,
        cityId: ICity['id'],
      ) => {
        const city: ICity = this.cities.find(
          (city) => city.value.id === cityId,
        )?.value;

        result[city.currency] = orders.reduce((acc, order) => {
          if (order.status < EOrderStatus.CONFIRMED) {
            return acc;
          }
          return acc + order.sum;
        }, 0);

        return result;
      },
      {},
    );

    const sumsStringified = _.map(
      sums,
      (value: number, key: string) => value + ' ' + key,
    ) as string[];

    return _.join(sumsStringified, ', ') as string;
  }

  cleanAddress(userId: string) {
    this.axios
      .put(`/users/${userId}/address`, { address: '' })
      .then(() => this.onSubmit());
  }

  /**
   * Prepares orders, providing them currencies of their
   * sums and a human-readable status
   */
  prepareOrders(orders: IOrder[]): PreparedOrder[] {
    return orders.map((order: IOrder) => {
      const statusColor = ORDER_STATUSES_COLORS[order.status] || '#666666';

      const currency = order.restaurant?.city?.currency,
        key: string = EOrderStatus[order.status] || 'noStatus',
        chatShown = false;

      const // colorStyle = `background-color: ${statusColor}4e;`,
        // headerStyle = `background-color: ${statusColor}8e;`,
        expandedColorStyle = `border-left: 5px solid ${statusColor}`,
        collapsedColorStyle = `border-top: 5px solid ${statusColor}`,
        statusBadgeColor = `background-color: ${statusColor}`;

      return {
        ...order,
        currency,
        colors: {
          expandedColorStyle,
          statusBadgeColor,
          collapsedColorStyle,
        },
        // headerStyle,
        chatShown,
      };
    });
  }

  mounted() {
    // Ensures showing orders on the section is only visited

    let ordersRequest: Promise<any>;

    switch (this.$store.getters.roleId) {
      case RoleEnum.ADMIN:
        ordersRequest = this.axios.get<IOrder[]>('/orders');

        break;
      case RoleEnum.CITY_ADMIN:
      case RoleEnum.OPERATOR:
        ordersRequest = this.axios.get<IOrder[]>('/orders', {
          params: { cityId: this.cityId },
        });

        break;
      case RoleEnum.RESTAURANT_OWNER:
        ordersRequest = this.axios.get<IOrder[]>('/orders', {
          params: { rests: [this.restaurantId] },
        });

        break;
    }

    ordersRequest.then(({ data }) => {
      this.orders = this.prepareOrders(data);
    });

    if (this.isAdmin) {
      this.axios.get('/restaurants').then(({ data }) => {
        this.restaurants = data;
      });

      this.axios.get<ICity[]>('/cities').then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    } else if (this.isCityAdmin) {
      this.axios.get('/restaurants/city/' + this.cityId).then(({ data }) => {
        this.restaurants = data;
      });
    }
  }

  exportExcel() {
    const restaurantIds = this.isRestaurantOwner
      ? [this.restaurantId]
      : this.filter.restaurants.map((rest) => rest.id);

    this.exporting = true;

    this.axios
      .get('/orders/export/excel', {
        params: {
          rests: restaurantIds,
          from: this.filter.from,
          to: this.filter.to,
          cityId: this.selectedCity?.id,
          locale: this.locale,
        },
        responseType: 'arraybuffer',
      })
      .then((response) => {
        this.downloadFile(response);
        this.exporting = false;
      });
  }

  downloadFile(file) {
    const { headers, data } = file,
      contentDisposition = headers['content-disposition'],
      contentType = headers['content-type'];

    let filename = 'orders' + format(new Date(), 'yyMMddHHmmss') + '.xlsx';

    if (contentDisposition) {
      if (/=utf-8/.test(contentDisposition)) {
        [, filename] = contentDisposition.match(/filename\*=utf-8''(.*)/);
      } else {
        [, filename] = contentDisposition.match(/filename="?([^"]+)"?/);
      }
    }

    const url = window.URL.createObjectURL(
      new Blob([data], { type: contentType }),
    );
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', decodeURI(filename));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return { filename };
  }

  onSubmit() {
    const restaurantIds = this.isRestaurantOwner
      ? [this.restaurantId]
      : this.filter.restaurants.map((rest) => rest.id);

    this.loading = true;

    this.axios
      .get('/orders', {
        params: {
          rests: restaurantIds,
          from: this.filter.from,
          to: this.filter.to,
          cityId: this.selectedCity?.id,
        },
      })
      .then((response) => {
        this.orders = this.prepareOrders(response.data);
        this.loading = false;
      });
  }
}
</script>
