"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkMailingProperties = exports.Valued = exports.ContactType = exports.LangList = void 0;
const helpers_1 = require("../helpers");
exports.LangList = ['en', 'es', 'ru', 'ua'];
var ContactType;
(function (ContactType) {
    ContactType["Telegram"] = "Telegram";
    ContactType["Whatsapp"] = "Whatsapp";
    ContactType["Phone"] = "Phone";
})(ContactType = exports.ContactType || (exports.ContactType = {}));
class Valued {
}
exports.Valued = Valued;
exports.checkMailingProperties = (0, helpers_1.checkProperties)([
    'text',
    'cityId',
]);
